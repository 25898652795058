// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*const dev = 'https://giktekonsole.com/gateway/individual/api/v1/';
const devOcr = 'https://giktekonsole.com/ocr/';
export const environment = {
  production: false,
  baseUrl: dev,
  devOcr,
};*/

//782054be-default-ingresscu-b52c-681254839.us-east-1.elb.amazonaws.com


/*const dev = 'https://prod-onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/v1/';
const devOcr = 'https://prod-onboarding.stanbicbank.co.ke/';
//const devOcr = 'http://782054be-default-ingresscu-b52c-681254839.us-east-1.elb.amazonaws.com/';
// const imageUrl = "https://uat-onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";
const imageUrl = "https://prod-onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";
*/



const dev = 'https://uat-onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/v1/';
const dev2 = 'https://uat-onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/business/v1/';
const dev3 = 'https://uat-onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/business/v2/';
const devOcr = 'https://uat-onboarding.stanbicbank.co.ke/';
const imageUrl = "https://uat-onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";




/*
const dev = 'https://onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/v1/';
const devOcr = 'https://onboarding.stanbicbank.co.ke/';
//const devOcr = 'http://782054be-default-ingresscu-b52c-681254839.us-east-1.elb.amazonaws.com/';
// const imageUrl = "https://uat-onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";
const imageUrl = "https://onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";
*/

/*
const dev = 'https://onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/v1/';
const dev2 = 'https://onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/business/v1/';
const dev3 = 'https://onboarding.stanbicbank.co.ke/rest/individual-onboarding/api/business/v2/';
const devOcr = 'https://onboarding.stanbicbank.co.ke/';
const imageUrl = "https://onboarding.stanbicbank.co.ke/rest/sms-mcs/image/getImage/";
*/


export const environment = {
  production: true,
  baseUrl: dev,
  baseBusiness: dev2,
  baseBusinessV2: dev3,
  devOcr,
  imageUrl: imageUrl
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
