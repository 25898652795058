import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./splashscreen/splashscreen.module').then(
        (m) => m.SplashscreenPageModule
      ),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'onboarding-business',
    loadChildren: () =>
      import('./onboarding-business/onboarding-business.module').then((m) => m.OnboardingBusinessModule),
  },
  
  // localhost:8100/invitee-business?token=b4add6e3-f8c8-4a45-add6-e3f8c83a45622

  {
    path: 'invitee-business',
    loadChildren: () =>
      import('./invited/business/business-innvited.module').then((m) => m.BusinessInnvitedModule),
  },
  {
    path: 'liveliness',
    loadChildren: () =>
      import('./liveliness/liveliness.module').then((m) => m.LivelinessModule),
  },
  {
    path: 'desktop-redirect',
    loadChildren: () =>
      import('./desktop-redirect/desktop-redirect.module').then(
        (m) => m.DesktopRedirectModule
      ),
  },
  {
    path: '**', // fallback router must in the last 
    loadChildren: () =>
    import('./splashscreen/splashscreen.module').then(
      (m) => m.SplashscreenPageModule
    ),

  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
