import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  Router,
  NavigationStart,
  Event as NavigationEvent,
  NavigationEnd,
} from "@angular/router";
import { ApiService } from "./_services/api.service";
import { CookieService } from "ngx-cookie-service";
import { DataStoreService } from "./_services/data-store.service";

import { filter } from "rxjs/operators";
import { ResBranches, ResBusinessActivities, ResBusinessTypes, ResChequeB, ResTurnOver } from "./_models/business-drop-down.model";

declare let gtag: Function;

declare let dataLayer: any;
declare let _satellite: any;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private apiService: ApiService
  ) {
    this.initializeApp();
    this.googleAnalytics();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getDropDowns();

      if (this.platform.is('desktop')) {

         //  this.router.navigateByUrl('/desktop-redirect', { replaceUrl: false });
         
      }


      /**  Listener to help redirecting url when a user activate editing mode on the summary page */
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {

          const summary_edit_flag = localStorage.getItem('summary_edit_flag');
          const last_url = localStorage.getItem('last_url');

          const url = event.url;

          if (summary_edit_flag && last_url) {

            if (summary_edit_flag == last_url) {

              if (localStorage.getItem('edit-biz-on')) {

                localStorage.removeItem('summary_edit_flag');
                this.router.navigateByUrl('/onboarding-business/confirmation', { replaceUrl: false }).then(() =>{

                  localStorage.removeItem('edit-biz-on');
                  
                })

              } else {
                localStorage.removeItem('summary_edit_flag');
                this.router.navigateByUrl('/onboarding/summary', { replaceUrl: false });
              }

             

            }

          }


          localStorage.setItem('last_url', url);

        });
    });
  }

  getDropDowns() {
    this.getCountries();
    this.getBranches();
    this.getRelationships();
    this.getOccupations();
    this.getIndustry();
    this.getEmployers();
    this.getIncomes();
    this.getCurrencies();

    // load business drop downs 
    setTimeout(() => {

      this.getBusinessTypes();
      this.getBusinessActivities();
      this.getTurnOver();
      this.getBranchesBiz();
      this.getCheckbookBiz();
      
    }, 1000);
  }
  // Get Branches
  getBranches() {
    this.apiService.getBranches().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("branches", JSON.stringify(res.object.info));
      }
    });
  }
  // Get Industries
  getCountries() {
    this.apiService.getCountries().subscribe(async (res) => {
      if (res.successful) {
        const countries = await res.object.info;
        let country = [];
        const filterArrayOfCountries = countries.filter(
          (c) => c.countryCode === "KE"
        );
        const other = filterArrayOfCountries[0];
        country = [{ ...other }, ...countries];
        localStorage.setItem("countries", JSON.stringify(country));
      }
    });
  }

  // Get Relationships
  getRelationships() {
    this.apiService.getRelationships().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("relationships", JSON.stringify(res.object.info));
      }
    });
  }

  // Get list of occupations
  getOccupations() {
    this.apiService.getOccupations().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("occupations", JSON.stringify(res.object.info));
      }
    });
  }

  // Get list of industries
  getIndustry() {
    this.apiService.getIndustries().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("industries", JSON.stringify(res.object.info));
      }
    });
  }

  // Get employer
  getEmployers() {
    this.apiService.getEmployers().subscribe((res) => {
      if (res.successful) {
        const employers = res.object.info;
        const filterArrayOfEmployers = employers.filter(
          (e) => e.companyName === "OTHER"
        );
        const other = filterArrayOfEmployers[0];
        other.companyName = "OTHER (If not available on the list)";
        const employer = [{ ...other }, ...employers];
        localStorage.setItem("employers", JSON.stringify(employer));
      }
    });
  }

  // Get monthly income
  getIncomes() {
    this.apiService.getIncomes().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("incomes", JSON.stringify(res.object.info));
      }
    });
  }

  // Get List of currencies
  getCurrencies() {
    this.apiService.getCurrencies().subscribe((res) => {
      if (res.successful) {
        localStorage.setItem("currencies", JSON.stringify(res.object.info));
      }
    });
  }

  /** Listen to google analytics */ 
  googleAnalytics() {

    this.router.events.subscribe(event => {

      if(event instanceof NavigationEnd){

          gtag('config', 'G-7P8ZJSYHHT', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
       }
    })
 
    
  }

  /** === Business Banking drop down */

  getBusinessTypes() {

    try {

      this.apiService.getBusinessTypes().subscribe((response : ResBusinessTypes) =>{

        if (response.successful) {

          localStorage.setItem("businessTypes", JSON.stringify(response.object.info));

        }
        
      }, (error: any) =>{})
      
    } catch (error) {

      
      
    }
  }

  getBusinessActivities() {

    try {

      this.apiService.getBusinessActivities().subscribe((response: ResBusinessActivities)=> {

        if (response.successful) {

          localStorage.setItem("businessActivities", JSON.stringify(response.object.info));
        }

       }, (error: any) =>{})
      
    } catch (error) {
      
    }
  }

  getTurnOver() {
    try {

      this.apiService.getTurnOver().subscribe((response: ResTurnOver) =>{

        if (response.successful) {

          localStorage.setItem("turnOver", JSON.stringify(response.object.info));

        }
 
      }, (error) =>{});
      
    } catch (error) {
      
    }
  }

  getBranchesBiz() {

    try {

      this.apiService.getBranchesBiz().subscribe((response: ResBranches) =>{

        if (response.successful) {

          localStorage.setItem("branchesBiz", JSON.stringify(response.object.info));

        }
      }, (error) =>{})
      
    } catch (error) {
      
    }
  }

  getCheckbookBiz() {

    try {

      this.apiService.getCheckBookBiz().subscribe((response: ResChequeB) =>{

        if (response.successful) {

          localStorage.setItem("chequeBiz", JSON.stringify(response.object.info));

        }
      }, (error) =>{})
      
    } catch (error) {
      
    }
  }

  adobeTracking() {

    var deviceCheck = /Mobile/i.test(navigator.userAgent) ? "Mobile" : "Desktop";	

    dataLayer = {								
      ecommerce : {							
        purchaseID : "1234567890",						
        state : "purchase",						
        product : [{						
          category : "LMM",					
          family : "Wealth Bank Accounts",					
          quantity: "1",					
          price : 0,					
          sku : "0987654321",					
          currencyCode : "ZAR"					
        }],						
      },							
                    
      search : {							
        filter : "business"						
      },							
      numSearchResults : "16",							
      searchTerm : "loan",							
                    
      loginStatus : "guest",							
      userLoginSucces : "false",							
      userRegistrationSuccess : "false",							
      deviceType : deviceCheck,							
                    
      formisSubmitted : false,							
      formName : "checkeligibility | wealth bank accounts | platinum optimum bank account",							
      formStatus : "complete",							
      leadStatus : "eligible",							
      leadID : "{insert-unique-id}",							
      application : {							
        applicationProduct : "Strata Bank Account",						
        applicationMethod : "online",						
        applicationID : "{insert-unique-id}",						
        applicationName : "application: pbbi | wealth bank accounts | platinum optimum",						
        applicationStep : "Step 1",						
        applicationStart : true,						
        applicationComplete : false						
      },							
                    
      siteErrorCode : "4401 : unauthorised call : guid:noguid : /login",							
      siteCountry : "Kenya",							
      websiteName : "Kenya Account Opening",							
      websiteNameCode : "KAO",							
      siteLanguage : "English",							
                    
      pageCategory : "Personal",							
      pageName : "Standard Bank community:talk:general banking:how do i transfer money between two linked accounts online",							
      pageSubSection1 : "Standard Bank community",							
      pageSubSection2 : "Standard Bank community:talk",							
      pageSubSection3 : "Standard Bank community:talk:general banking",							
      pageSubSection4 : "Standard Bank community:talk:general banking:how do i transfer money between two linked accounts online"							
                    
                    
    };	


  }
}


/**
 * Onboarding items
 * 
1. Mandate- those where a signatory is selected seem to duplicate the signatories chosen : DONE

2. Foreigner journey- a non kenyan director should key in their gender and fill in a form before capturing their nationalId, signature and selfie

3.Business Types- sole proprietorship requirements are showing requirements for the other business types and not those specific to it. :DONE

4. Business Types- create links for the unregistered business types to be able to take the customer to business pamoja(unreg sole)/ joint(unreg chama) : DONE
 */