import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResBranches, ResBusinessActivities, ResBusinessTypes, ResChequeB, ResTurnOver } from '../_models/business-drop-down.model';
import { ResAccountProducts } from '../_models/business-model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;
  baseUrlBiz = environment.baseBusiness;
  baseUrlV2 = environment.baseBusinessV2;
  constructor(private http: HttpClient) {}

  // Get Account Types
  getAccountTypes(): Observable<any> {
    return this.http.get(this.baseUrl + 'accountType');
  }

  // // Get account type bundle product
  // getAccountTypeBundleProduct(): Observable<any> {
  //   return this.http.get(this.baseUrl + 'bundleProduct');
  // }

  
  // Get account type bundle product
  getAccountTypeBundleProduct(tagId: string): Observable<ResAccountProducts> {
    return this.http.get<any>(this.baseUrlV2 + 'bundleProduct?accountTag=' + tagId);
  }

  // Auth service
  login(payload): Observable<any> {
    return this.http.post(this.baseUrl + 'auth', payload);
  }

  // Verify OTP
  verifyOTP(payload): Observable<any> {
    return this.http.post(this.baseUrl + 'otp', payload);
  }

  // Scan Front ID
  scanFrontID(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.devOcr}frontid`, payload);
  }

  // Scan Back ID / Passport
  scanBackID(payload): Observable<any> {
    return this.http.post(`${environment.devOcr}ocr`, payload);
  }

  // Save Images
  saveImage(payload: any): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(this.baseUrl + 'image', formData);
  }

  // Save Images  TODO: HACK FOR BIZ
  saveImageBIZ(payload: any): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(this.baseUrlBiz + 'identityDocument', formData);
  }

   // Save selfie for business of foreign directors 
   saveImageForeignBIZ(payload: any): Observable<any> {

    const formData = new FormData();
    for (const key in payload) {
      if (payload) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(this.baseUrlBiz + 'foreignerDetails', formData);
  }

  // Save Preferences
  savePreferences(payload: any): Observable<any> {
    return this.http.post(this.baseUrl + 'preferences', payload);
  }

  // Get branches
  getBranches(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/branch');
  }

  // Get countries
  getCountries(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/country');
  }

  // Get employers
  getEmployers(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/employer');
  }

  // Get industries
  getIndustries(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/industry');
  }

  // Get occupations
  getOccupations(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/occupation');
  }

  // Get occupations
  getRelationships(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/relation');
  }

  // Get Incomes
  getIncomes(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/income');
  }

  // Get currencies
  getCurrencies(): Observable<any> {
    return this.http.get(this.baseUrl + 'dropdown/currency');
  }

  // Save Occupation
  saveOccupation(payload): Observable<any> {
    return this.http.post(this.baseUrl + 'occupation', payload);
  }

  // Save Selfie
  saveSelfie(payload): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(this.baseUrl + 'selfie', formData);
  }

  // Create Account
  createAccount(): Observable<any> {
    return this.http.post(this.baseUrl + 'confirmation', {});
  }

  // Create Joint Account
  triggerJointAccount(): Observable<any> {

    return this.http.post(this.baseUrl + 'joint/invited', {});

  }

  // Create a child account
  createChild(payload): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      if (payload) {
        formData.append(key, payload[key]);
      }
    }
    return this.http.post(this.baseUrl + 'child', formData);
  }

  // create joint account
  createJointAcc(payload): Observable<any> {
    return this.http.post(this.baseUrl + 'joint', payload);
  }

  // Get Joint Account Inviter Details
  getJointAccountInviter(): Observable<any> {
    return this.http.get(this.baseUrl + 'joint');
  }

  // Get Summary of user account
  getSummary(): Observable<any> {
    return this.http.get(this.baseUrl + 'selfie');
  }

  // Notification account details
  getReminderDetails(): Observable<any> {
    return this.http.get(this.baseUrl + 'reminder');
  }


  /** Business banking drop down ===  ======= */

  // 1. Business types
  getBusinessTypes(): Observable<ResBusinessTypes> {

    return this.http.get<ResBusinessTypes>(this.baseUrlBiz + 'dropdownBusinessType');

  }

   // 2. Business Activities
   getBusinessActivities(): Observable<ResBusinessActivities> {

    return this.http.get<ResBusinessActivities>(this.baseUrlBiz + 'dropdownBusinessActivities');

  }

   // 3. Turn over
   getTurnOver(): Observable<ResTurnOver> {

    return this.http.get<ResTurnOver>(this.baseUrlBiz + 'dropdownTurnover');

  }

   // 4. Branches
   getBranchesBiz(): Observable<ResBranches> {

    return this.http.get<ResBranches>(this.baseUrlBiz + 'dropdownBranches');

  }

  getCheckBookBiz(): Observable<ResChequeB> {

    return this.http.get<ResChequeB>(this.baseUrlBiz + 'dropdownChebookpages');

  }
}
